import React from 'react';
import {Paper, Grid, Container, Typography, Button, Box} from '@material-ui/core';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { sections, messagingAPI } from '../../utils/hp-sections-data'

class Sections extends React.Component {

    state = {
        activeIndex: 0,
        selectedAPI: messagingAPI[0].image,
    };

    carouselimages = () => {
        let { imagesPrefix } = this.props;
        return (
            <Carousel autoPlay infiniteLoop showStatus={false} interval={5000} showArrows={false} showIndicators={false} showThumbs={false} swipeable={true} selectedItem={this.state.activeIndex} onChange={(e) => {this.setState({activeIndex: e})}}>
                <div >
                    <img src={`${imagesPrefix}/blix-Desktop-email-Mac.webp`} alt="blix email on mac"/>
                </div>
                <div>
                    <img src={`${imagesPrefix}/blix-Desktop-Chat-Mac.webp`} alt="blix chat on mac"/>
                </div>
                <div>
                    <img src={`${imagesPrefix}/Blix-Tasks-Later-Board.webp`} alt="blix tasks  on mac"/>
                </div>
                <div>
                    <img src={`${imagesPrefix}/blix-Desktop-Calendar-Mac.webp`} alt="blix calendar on mac"/>
                </div>
                <div>
                    <img src={`${imagesPrefix}/blix-Desktop-Chat-Contacts-Mac.webp`} alt="blix chat contacts on mac"/>
                </div>
            </Carousel>
        );
    }

    selectedAPI = (selectedAPI) => {
        this.setState({selectedAPI});
    }
    
    async toggleClass(index){
        await this.setState({activeIndex: index})
    };

    splashOpen = (url) => {
        let winName = 'window';
        let win = window.open(url,winName, 'width=800, height=700');
        return win;
    }

    sectionOrder = (section) => {//Section Order (image-text)(text-image)
        let { imagesPrefix } = this.props
        if(section.imagefirst){
            return (
                <Container fixed className="container">
                <Box display="flex">
                    <Grid container spacing={10} >
                        <Box clone order={{ xs: 2, sm: 2 , md: 1, lg: 1}}>
                            <Grid item xs={12} sm={12} md={6} className="icon-align" style={{paddingTop: '0'}}>
                                {/* {section.image} */}
                                <img src={imagesPrefix + section.image} alt={section.title} />
                            </Grid>
                        </Box>
                        <Box clone order={{ xs: 1, sm: 1, md: 2 , lg: 2}}>
                            <Grid item xs={12} sm={12} md={6} >
                                <Typography variant="h3" color="inherit" className="title">{section.title}</Typography>
                                <div className="sub-text">
                                    {section.hasOwnProperty('subtext1') ? section.subtext1 : ''} 
                                    <div className="second-sub-text">
                                        {section.hasOwnProperty('subtext2') ? section.subtext2 : ''}
                                    </div>
                                    {section.classprop === 'third-section-wrapper' ? 
                                    <div style={{ textAlign: 'center'}}>
                                        <Button style={{fontWeight: 'bold', background: '#4571C0',  margin: '30px auto', color: '#fff'}} variant="contained" onClick={() => this.splashOpen('https://wge.blix.net')}>
                                            Create Widget
                                        </Button> 
                                    </div>
                                   : ''}
                                </div>
                            </Grid>
                        </Box>
                    </Grid>
                </Box>
                </Container>
            );
        }else {//second section
            return (
                <Container maxWidth={false} className="container">
                    <Grid container spacing={10}>
                        <Grid item xs={12} sm={12} md={4} className="section-text-place">
                            <div className="sub-text">
                            <Typography variant="h3" color="inherit" className="title">{section.title}</Typography>
                                {section.hasOwnProperty('subtext1') ? section.subtext1 : ''} 
                                <div className="second-sub-text">
                                    {section.hasOwnProperty('subtext2') ? section.subtext2 : ''}
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={8} className="item-padding">
                            <Grid container direction="row" justify="space-evenly" alignItems="center" spacing={0} className="section-btn-wrapper">
                                {messagingAPI.map((message, i) => {
                                    return (
                                            <Grid item key={i} xs={4} sm={2} md={2} lg={2} className="section-grid-item-btn">
                                                <Button onClick={() => {this.toggleClass(i); this.selectedAPI(i)}} className={ (this.state.activeIndex === i)  ? 'active' : ''} >{message.name}</Button>
                                            </Grid>
                                        );
                                    })}
                            </Grid>
                            <Grid container direction="row" justify="center" alignItems="center" >
                                <Grid item lg={12} md={12} sm={12} xs={12} style={{marginTop: '40px'}}>
                                    <Carousel className="carousel-wrapper" showThumbs={false} showIndicators={false} showStatus={false}>
                                        <div>
                                        <img src={`${imagesPrefix}/blix-MacbookPro.webp`} alt="blix - macbook pro"/>
                                        <div className="carousel-img-wrapper">{this.carouselimages()}</div>
                                        </div>
                                    </Carousel>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            );
        }
    }

    render(){
        return (
            sections.map((section, i) => {
                return (
                    <Paper className={section.classprop} key={i} >
                        {this.sectionOrder(section)}
                    </Paper>
                );
            })
        );
    }
    
}
export default Sections;
