import React from 'react';
import {Grid, Container, Paper} from '@material-ui/core';
import GmailLogo from '../../images/gmail.svg'
import OfficeLogo from '../../images/office.svg'
import DovecotLogo from '../../images/dovecot.svg'
import ImapLogo from '../../images/IMAP.svg'
import MsExchangeLogo from '../../images/blix-microsoft-exchange-logo.svg'

const mailPlatformsSvg= [
    {
        logo: <div style={{width: '50px', margin: 'auto'}}><GmailLogo/></div>
    },
    {
        logo: <div style={{width: '85px', margin: 'auto'}}><DovecotLogo/></div>
    },
    {
        logo: <div style={{width: '50px', margin: 'auto'}}><OfficeLogo/></div>
    },
    {
        logo: <div style={{width: '50px', margin: 'auto'}}><MsExchangeLogo/></div>
    },
    {
        logo: <div style={{width: '50px', margin: 'auto'}}><ImapLogo/></div>
    }
]

const MailPlatformNav = () => {
    return (

        <Paper className="mail-platforms-wrapper">
            <Container fixed  className="container">
                <Grid container spacing={3} style={{ alignItems: 'center'}}>
                    {mailPlatformsSvg.map((mailplatform, i) => {
                        return (
                            <Grid key={i} item xs style={{textAlign: '-webkit-center', padding: '7px'}} >
                                    {mailplatform.logo}
                            </Grid>
                        );
                    })}
                </Grid>
            </Container>
        </Paper>);
}

export default MailPlatformNav;