import React from 'react';

export const sections = [
    {
        title: 'Modernizing any email',
        subtext1: 'The Blix platform was designed to integrate best-of-breed email, chat, calendar, contacts, later board, and productivity features so every user and organization can find their own workflow balance',
        subtext2: 'Blix makes managing your team’s collaboration easy, simple, and affordable by organizing it all in one app',
        image: "/images/why-you-need-blix.webp",
        classprop: 'first-section-wrapper',
        imagefirst: true
    },
    {
        title: 'Work your way',
        subtext1: 'Give your team the ability to manage their projects in the way they work best and easily switch between views for ultimate flexibility.',
        classprop: 'second-section-wrapper',
        imagefirst: false
    },
    {
        title: 'Messaging Bridge',
        subtext1: 'The Messaging Bridge is a powerful capability which allows your organization to communicate with any person directly to your Blix chat teams. Each team can be designated with a different mailbox address. Users sending emails to the mailbox address can either be inside or outside your organization. Upon receipt of an email by team members, they can discuss internally before replying to the sender. You can integrate our Widget to your website to enable visitors to interact with your team anonymously using incognito email addresses. For example, a visitor to your website can provide you with a candid feedback without revealing his email address.',
        image: "/images/blix-form.webp",
        classprop: 'third-section-wrapper',
        imagefirst: true
    },
];

export const messagingAPI = [
    {
        name: 'Email',
        image: 0
    },
    {
        name: 'Chat',
        image: 1
    },
    {
        name: 'Tasks',
        image: 2
    },
    {
        name: 'Calendar',
        image: 3
    },
    {
        name: 'Contacts',
        image: 4
    }
]