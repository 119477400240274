import React from 'react'
import { Paper, Container,Typography, Grid,Avatar } from '@material-ui/core';
import BM from '../../images/hp-features/bluemail.svg';
import UnifiedMsg from '../../images/hp-features/unified-messaging.svg';
import Imail from '../../images/hp-features/iMail.svg';
import Tasks from '../../images/hp-features/later-board.svg';
import Share from '../../images/hp-features/share.svg';
import IntefratedCalendar from '../../images/hp-features/calendar.svg';
import DownloadsSection from '../downloads-section';

const downSection = [
    {
        title: 'Features you’ll love',
        subtitle: 'Welcome to the future of team communication. Blix stands for email messaging on any device combined with great chat features that you can’t find in your default email or chat app.',
        classname: 'first-down-section-wrapper',
        subsection: [
            //{
            //    icon: <Link style={{ width: '80px', margin: 'auto', display: 'block'}} to='features/instant-push'><Avatar className="avatar"><BellIcon/></Avatar></Link>,
            //    title: <Link to='features/instant-push' style={{color: '#000', textDecoration: 'none'}}>Instant Push</Link>,
            //    subtitle: <Link style={{color: '#000', textDecoration: 'none'}} to='features/instant-push'>'Blix intelligently pushes your emails so they arrive to your device at instantaneous speeds saving valuable time.'</Link>
            //},
            {
                icon: <Avatar className="avatar"><UnifiedMsg style={{width: '40px'}}/></Avatar>,
                title: 'Unified Messaging',
                subtitle: 'Streamline your organizational messaging with a single tap away to switch between your emails and chats. Interact via teams and direct messages in real-time'
            },
            {
                icon: <Avatar className="avatar"><BM style={{width: '39px'}}/></Avatar>,
                title: 'BlueMail',
                subtitle: 'Blix takes the best of Blue Mail, our world class email service used by millions of people worldwide. Add one or more of your Office365, Exchange, Google, IMAP or POP3 or any other mail account'
            },
            {
                icon: <Avatar className="avatar"><Imail/></Avatar>,
                title: 'iMail',
                subtitle: 'A hybrid of modern messaging with email is a real-time end-to-end secured email for teams enabling context-based conversations that are intuitive to anyone using chat today'
            },
            {
                icon: <Avatar className="avatar"><Tasks style={{width: '37px'}}/></Avatar>,
                title: 'Later Board',
                subtitle: 'Manage your own inbox with the Later Board, turning emails into actionable tasks. Push  emails to either Today, Later, or Done, add personal notes, prioritize actions, and get your work done quickly and efficiently'
            },
            {
                icon: <Avatar className="avatar"><Share style={{width: '35px'}}/></Avatar>,
                title: 'Share Email',
                subtitle: 'Blix’s new workflow enables you to simply share an email with your workgroup, so that all members of the team can view and comment on it in real-time'
            },

            {
                icon: <Avatar className="avatar"><IntefratedCalendar style={{width: '37px'}}/></Avatar>,
                title: 'Integrated Calendar',
                subtitle: 'Access your Calendar and plan events right from within Blix. Customize exactly how and when you receive your Calendar reminders'
            },
            
            
            
            // {
            //     icon: <Brightness/>,
            //     title: 'Dark Theme',
            //     subtitle: 'Seamlessly and automatically switch between day and night mode with ease to make late night emails easier to take care of.'
            // },
          
            
            // {
            //     icon: <Tasks/>,
            //     title: 'Get Stuff Done',
            //     subtitle: 'Quickly mark emails to be handled later and set reminders for them. Let Blix help turn your to-do list of emails into a do-done list.'
            // },
        ],
    },
    {
        title: 'Sync between all your devices',
        subtitle: 'Setup is an effortless breeze and by simply signing into your email accounts, your inbox will be ready and waiting for you',
        classname: 'second-down-section-wrapper',
        image: "/images/device-family-1.webp",
    },
]

export default function DownSections({imagesPrefix}) {
    return (
        downSection.map((section, i) => {
            return (
                <Paper key={i} className={section.classname}>
                    <Container  className="container">
                        <Typography variant="h3" className="title">{section.title}</Typography>
                        <Typography className="sub-title">{section.subtitle}</Typography>
                        {section.hasOwnProperty('image') ? 
                            <Grid >
                                <Grid item className="img">
                                    <img src={imagesPrefix + section.image} alt="device family."/>
                                </Grid>
                                <DownloadsSection direction="row"/>
                            </Grid> 
                            : ''}
                        {section.hasOwnProperty('subsection') ? 
                            <Grid container direction="row" justify="center" alignItems="center" spacing={3} className="items-root">
                                {section.subsection.map((sub, i) => {
                                    return (
                                        <Grid key={i} item md={5} sm={5} xs={12} style={{minWidth: '280px', padding: '0'}}>

                                            {/* <Typography style={{color: '#000', height: '40px'}}>{sub.icon}</Typography> */}
                                            {sub.icon}
                                            <Typography className="items-title">{sub.title}</Typography>
                                            <Typography className="items-subtitle">{sub.subtitle}</Typography>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        : ''}
                    </Container>
                </Paper>
            );
        })
    );
}
