import React from 'react';
// import ResAppBar from './res-app-bar';
import TopPageCenter from './top-page-center';
import Paper from '@material-ui/core/Paper';

const TopPage = ({imagesPrefix}) => {
    return (
        <Paper className="top-page-wrapper">
            <TopPageCenter imagesPrefix={imagesPrefix} />
        </Paper>
    );
}

export default TopPage;